import { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const checkAuthStatus = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = localStorage.getItem('access-token');

    setIsAuthenticated(!!accessToken);

    if (user && user.role === 'Admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    checkAuthStatus();

    const handleAuthChange = () => {
      checkAuthStatus();
    };

    window.addEventListener('authChanged', handleAuthChange);

    return () => {
      window.removeEventListener('authChanged', handleAuthChange);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');

    setIsAuthenticated(false);
    setIsAdmin(false);

    window.location.href = '/login';
  };

  return (
    <AppBar position='static' sx={{ backgroundColor: '#1a1a1a' }}>
      <Toolbar>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='logo'
          component={Link}
          to='/'
        >
          <Avatar src={'/logo.png'} alt='Logo' sx={{ width: 40, height: 40 }} />
        </IconButton>
        <Typography
          variant='h6'
          sx={{ flexGrow: 1, marginLeft: 1, fontWeight: 'bold' }}
        >
          Benfield Electric
        </Typography>

        {isAuthenticated ? (
          <>
            {!isAdmin && (
              <>
                <Button
                  color='inherit'
                  component={Link}
                  to='/'
                  startIcon={<DocumentScannerIcon />}
                >
                  Requisition Form
                </Button>
              </>
            )}

            {isAdmin && (
              <>
                <Button
                  color='inherit'
                  component={Link}
                  to='/'
                  startIcon={<DocumentScannerIcon />}
                >
                  Requisition Form
                </Button>
                <Button
                  color='inherit'
                  component={Link}
                  to='/admin'
                  startIcon={<AdminPanelSettingsIcon />}
                >
                  Admin Dashboard
                </Button>
              </>
            )}

            <Button
              color='inherit'
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Button
              color='inherit'
              component={Link}
              to='/signup'
              startIcon={<HowToRegIcon />}
            >
              Sign Up
            </Button>
            <Button
              color='inherit'
              component={Link}
              to='/login'
              startIcon={<LoginIcon />}
            >
              Login
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
