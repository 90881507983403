import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import { apiHost } from '../constants';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const downloadUrl = location.state?.downloadUrl;

  return (
    <Container maxWidth='md' sx={{ marginTop: '2rem', textAlign: 'center' }}>
      <Box
        sx={{
          padding: '3rem',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          borderRadius: '8px',
          backgroundColor: '#f7f9fb',
        }}
      >
        <Typography
          variant='h4'
          sx={{ fontWeight: 'bold', color: '#2c3e50', marginBottom: '2rem' }}
        >
          Thank You for using our service!
        </Typography>
        <Typography
          variant='body1'
          sx={{ marginBottom: '2rem', color: '#34495e' }}
        >
          Your requisition has been successfully submitted. You can download the
          requisition file by clicking the button below.
        </Typography>
        <Button
          variant='contained'
          color='primary'
          href={`${apiHost}${downloadUrl}`}
          download
          startIcon={<DownloadIcon />}
          sx={{
            backgroundColor: '#1abc9c',
            '&:hover': {
              backgroundColor: '#16a085',
            },
            padding: '10px 20px',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            borderRadius: '8px',
          }}
        >
          Download File
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => navigate('/')}
          download
          startIcon={<DocumentScannerIcon />}
          sx={{
            backgroundColor: '#3498db',
            '&:hover': {
              backgroundColor: '#2980b9',
            },
            marginLeft: '20px',
            padding: '10px 20px',
            fontSize: '1.2rem',
            fontWeight: 'bold',
            borderRadius: '8px',
          }}
        >
          New Inventory Submission
        </Button>
      </Box>
    </Container>
  );
};

export default ThankYouPage;
