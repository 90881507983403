import axios from './axios';

const verifyJobNumber = async (data) => {
  try {
    const response = await axios.post('/formData/verifyJob', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const createFormData = async (data) => {
  try {
    const response = await axios.post('/formData', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllFormDatas = async () => {
  try {
    const response = await axios.get('/formData');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteFormData = async (id) => {
  try {
    const response = await axios.delete(`/formData/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const formDataService = {
  verifyJobNumber,
  createFormData,
  getAllFormDatas,
  deleteFormData,
};

export default formDataService;
