import axios from './axios';

const createCategory = async (data) => {
  try {
    const response = await axios.post('/category', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllCategories = async () => {
  try {
    const response = await axios.get('/category');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateCategory = async (id, data) => {
  try {
    const response = await axios.patch(`/category/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteCategory = async (id) => {
  try {
    const response = await axios.delete(`/category/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const categoryService = {
  createCategory,
  getAllCategories,
  updateCategory,
  deleteCategory,
};

export default categoryService;
