import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import emailerListService from '../services/emailerList-service';

const EmailerListManagement = () => {
  const [emailersList, setEmailersList] = useState([]);
  const [newEmailerList, setNewEmailerList] = useState({ email: '' });
  const [editEmailerList, setEditEmailerList] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchEmailersList();
  }, []);

  const fetchEmailersList = async () => {
    const data = await emailerListService.getAllEmailersList();
    if (data.error) setEmailersList([]);
    else setEmailersList(data);
  };

  const handleOpenDialog = (emailerList = null) => {
    if (emailerList) {
      setEditMode(true);
      setEditEmailerList(emailerList);
      setNewEmailerList({ email: emailerList.email });
    } else {
      setEditMode(false);
      setNewEmailerList({ email: '' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditEmailerList(null);
  };

  const handleSaveEmailerList = async () => {
    if (editMode && editEmailerList) {
      await emailerListService.updateEmailerList(
        editEmailerList.id,
        newEmailerList
      );
    } else {
      await emailerListService.createEmailerList(newEmailerList);
    }
    fetchEmailersList();
    handleCloseDialog();
  };

  const deleteEmailerList = async (id) => {
    await emailerListService.deleteEmailerList(id);
    fetchEmailersList();
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Email List
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 3,
          backgroundColor: '#1abc9c',
          '&:hover': {
            backgroundColor: '#16a085',
          },
          ml: 'auto',
          display: 'flex',
          alignItems: 'center',
          minWidth: 180,
          padding: '10px 20px',
        }}
      >
        Add New Email
      </Button>

      {emailersList.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emailersList.map((emailerList) => (
                <TableRow hover key={emailerList.id}>
                  <TableCell>{emailerList.id}</TableCell>
                  <TableCell>{emailerList.email}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(emailerList)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteEmailerList(emailerList.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No emailers list available
        </Typography>
      )}

      {/* Dialog for Add/Edit Emailer List */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>{editMode ? 'Edit Email' : 'Add New Email'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Email'
            type='email'
            value={newEmailerList.email}
            onChange={(e) =>
              setNewEmailerList({ ...newEmailerList, email: e.target.value })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveEmailerList}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add Email'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailerListManagement;
