import axios from './axios';

const createAvailableShipping = async (data) => {
  try {
    const response = await axios.post('/availableShipping', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllAvailableShippings = async () => {
  try {
    const response = await axios.get('/availableShipping');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateAvailableShipping = async (id, data) => {
  try {
    const response = await axios.patch(`/availableShipping/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAvailableShipping = async (id) => {
  try {
    const response = await axios.delete(`/availableShipping/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const nameService = {
  createAvailableShipping,
  getAllAvailableShippings,
  updateAvailableShipping,
  deleteAvailableShipping,
};

export default nameService;
