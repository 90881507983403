import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import categoryService from '../services/category-service';

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ title: '', sortOrder: '' });
  const [editCategory, setEditCategory] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sortOrderError, setSortOrderError] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const data = await categoryService.getAllCategories();
    if (data.error) setCategories([]);
    else setCategories(data);
  };

  const handleOpenDialog = (category = null) => {
    if (category) {
      setEditMode(true);
      setEditCategory(category);
      setNewCategory({ title: category.title, sortOrder: category.sortOrder });
    } else {
      setEditMode(false);
      setNewCategory({ title: '', sortOrder: '' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditCategory(null);
    setSortOrderError('');
  };

  const handleSortOrderChange = (e) => {
    const value = e.target.value;
    setNewCategory({ ...newCategory, sortOrder: value });
  };

  const handleSaveCategory = async () => {
    const sortOrder = parseInt(newCategory.sortOrder);

    if (isNaN(sortOrder) || sortOrder <= 0) {
      setSortOrderError('Sort order must be a valid positive integer');
      return;
    }

    const existingCategoryWithSameSortOrder = categories.find(
      (category) =>
        category.sortOrder === sortOrder &&
        (!editMode || category.id !== editCategory.id)
    );

    if (existingCategoryWithSameSortOrder) {
      setSortOrderError(
        `Sort order ${newCategory.sortOrder} is already in use`
      );
      return;
    }

    if (editMode && editCategory) {
      await categoryService.updateCategory(editCategory.id, newCategory);
    } else {
      await categoryService.createCategory(newCategory);
    }

    fetchCategories();
    handleCloseDialog();
  };

  const deleteCategory = async (id) => {
    await categoryService.deleteCategory(id);
    fetchCategories();
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Categories
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 3,
          backgroundColor: '#1abc9c',
          '&:hover': {
            backgroundColor: '#16a085',
          },
          ml: 'auto',
          display: 'flex',
          alignItems: 'center',
          minWidth: 180,
          padding: '10px 20px',
        }}
      >
        Add New Category
      </Button>

      {categories.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Sort Order
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category) => (
                <TableRow hover key={category.id}>
                  <TableCell>{category.id}</TableCell>
                  <TableCell>{category.title}</TableCell>
                  <TableCell>{category.sortOrder}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(category)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteCategory(category.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No categories available
        </Typography>
      )}

      {/* Dialog for Add/Edit Category */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {editMode ? 'Edit Category' : 'Add New Category'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label='Category Title'
            value={newCategory.title}
            onChange={(e) =>
              setNewCategory({ ...newCategory, title: e.target.value })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
          <TextField
            label='Sort Order'
            type='number'
            value={newCategory.sortOrder}
            onChange={handleSortOrderChange}
            error={!!sortOrderError}
            helperText={sortOrderError}
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveCategory}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add Category'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CategoryManagement;
