import { useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import userService from '../services/user-service';

const Signup = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('access-token');
    if (accessToken) {
      navigate('/');
    }
  }, [navigate]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Full Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await userService.registerUser(values);

        if (response?.error) {
          toast.error(response?.error);
        } else {
          toast.success(response?.message);
          navigate('/login');
        }
      } catch (error) {
        toast.error('An error occurred during sign up');
      }
    },
  });

  return (
    <Container maxWidth='sm'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Typography
          variant='h4'
          gutterBottom
          sx={{ fontWeight: 'bold', color: '#1a1a1a' }}
        >
          Create Your Account
        </Typography>

        <Typography
          variant='body1'
          gutterBottom
          sx={{ color: 'text.secondary', marginBottom: 2 }}
        >
          Sign up to get started.
        </Typography>

        <TextField
          label='Full Name'
          variant='outlined'
          fullWidth
          margin='normal'
          id='name'
          name='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        />

        <TextField
          label='Email Address'
          variant='outlined'
          fullWidth
          margin='normal'
          id='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        />

        <TextField
          label='Password'
          variant='outlined'
          fullWidth
          margin='normal'
          type='password'
          id='password'
          name='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        />

        <Button
          variant='contained'
          fullWidth
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            borderRadius: '8px',
            padding: '12px 24px',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginTop: 3,
            '&:hover': {
              backgroundColor: '#1565c0',
            },
          }}
          onClick={formik.handleSubmit}
        >
          Sign Up
        </Button>
      </Box>
    </Container>
  );
};

export default Signup;
