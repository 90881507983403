import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Grid2,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import productService from '../services/product-service';
import categoryService from '../services/category-service';
import { apiHost } from '../constants';
import toast from 'react-hot-toast';

const ProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newProduct, setNewProduct] = useState({
    categoryId: '',
    itemCode: '',
    title: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [editProduct, setEditProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openMassUploadDialog, setOpenMassUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, []);

  const fetchProducts = async () => {
    const data = await productService.getAllProducts();
    if (data.error) setProducts([]);
    else setProducts(data);
  };

  const fetchCategories = async () => {
    const data = await categoryService.getAllCategories();
    if (data.error) setCategories([]);
    else setCategories(data);
  };

  const handleOpenDialog = (product = null) => {
    if (product) {
      setEditMode(true);
      setEditProduct(product);
      setNewProduct({
        categoryId: product.categoryId,
        itemCode: product.itemCode,
        title: product.title,
      });
      setSelectedImage(product.imageUrl || null);
    } else {
      setEditMode(false);
      setNewProduct({ categoryId: '', itemCode: '', title: '' });
      setSelectedImage(null);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditProduct(null);
    setSelectedImage(null);
  };

  const handleSaveProduct = async () => {
    const formData = new FormData();
    formData.append('categoryId', newProduct.categoryId);
    formData.append('itemCode', newProduct.itemCode);
    formData.append('title', newProduct.title);

    if (selectedImage && selectedImage instanceof File) {
      formData.append('image', selectedImage);
    }

    if (editMode && editProduct) {
      await productService.updateProduct(editProduct.id, formData);
    } else {
      await productService.createProduct(formData);
    }
    fetchProducts();
    handleCloseDialog();
  };

  const deleteProduct = async (id) => {
    await productService.deleteProduct(id);
    fetchProducts();
  };

  const deleteAllProducts = async () => {
    await productService.deleteAllProducts();
    fetchProducts();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleOpenMassUploadDialog = () => {
    setOpenMassUploadDialog(true);
  };

  const handleCloseMassUploadDialog = () => {
    setOpenMassUploadDialog(false);
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const allowedFormats = [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];

    const file = e.target.files[0];

    if (file) {
      if (!allowedFormats.includes(file.type)) {
        setFileError('Invalid file format. Please upload a CSV or Excel file.');
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
        setFileError('');
      }
    }
  };

  const handleMassUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await productService.massUploadProducts(formData);
      if (response.error) {
        toast.error(response.error);
        return;
      }
      fetchProducts();
      handleCloseMassUploadDialog();
      toast.success(response.message || 'Materials uploaded successfully');
    } catch (error) {
      console.error('Mass upload failed', error);
      toast.error('Mass upload failed');
    }
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Materials
      </Typography>

      <Grid2 container spacing={2} justifyContent='flex-end'>
        <Grid2 item>
          <Button
            startIcon={<DeleteIcon />}
            variant='contained'
            onClick={deleteAllProducts}
            sx={{
              backgroundColor: 'error.main',
              '&:hover': {
                backgroundColor: 'error.dark',
              },
              display: 'flex',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            Delete All Materials
          </Button>
        </Grid2>
        <Grid2 item>
          <Button
            startIcon={<DownloadIcon />}
            variant='contained'
            onClick={() =>
              (window.location.href = `${apiHost}/templates/Materials Mass Upload Template.xlsx`)
            }
            sx={{
              backgroundColor: '#3498db',
              '&:hover': {
                backgroundColor: '#2980b9',
              },
              display: 'flex',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            Download Template
          </Button>
        </Grid2>
        <Grid2 item>
          <Button
            startIcon={<UploadFileIcon />}
            variant='contained'
            onClick={handleOpenMassUploadDialog}
            sx={{
              backgroundColor: '#f39c12',
              '&:hover': {
                backgroundColor: '#e67e22',
              },
              display: 'flex',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            Mass Upload
          </Button>
        </Grid2>
        <Grid2 item>
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            onClick={() => handleOpenDialog()}
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
              display: 'flex',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            Add New Material
          </Button>
        </Grid2>
      </Grid2>

      {products.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3, marginTop: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Item Code
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow hover key={product.id}>
                  <TableCell>{product.id}</TableCell>
                  <TableCell>
                    {product.imageUrl ? (
                      <img
                        src={`${apiHost}${product.imageUrl}`}
                        alt={product.title}
                        style={{ width: '50px', height: '50px' }}
                      />
                    ) : (
                      <ImageIcon />
                    )}
                  </TableCell>
                  <TableCell>{product.Category.title}</TableCell>
                  <TableCell>{product.itemCode}</TableCell>
                  <TableCell>{product.title}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(product)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteProduct(product.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No materials available
        </Typography>
      )}

      <Dialog
        open={openMassUploadDialog}
        onClose={handleCloseMassUploadDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Mass Upload Materials</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Button
              variant='contained'
              component='label'
              startIcon={<UploadFileIcon />}
              sx={{
                backgroundColor: '#f39c12',
                '&:hover': {
                  backgroundColor: '#e67e22',
                },
                padding: '10px 20px',
              }}
            >
              Select File
              <input
                type='file'
                accept='.csv, .xlsx'
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {selectedFile && (
              <Typography sx={{ mt: 1, ml: 1 }}>{selectedFile.name}</Typography>
            )}

            {fileError && (
              <Typography color='error' sx={{ mt: 1, ml: 1 }}>
                {fileError}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMassUploadDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleMassUpload}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            Upload File
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {editMode ? 'Edit Material' : 'Add New Material'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin='normal' variant='outlined'>
            <InputLabel>Category</InputLabel>
            <Select
              value={newProduct.categoryId}
              onChange={(e) =>
                setNewProduct({ ...newProduct, categoryId: e.target.value })
              }
              label='Category'
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label='Material Title'
            value={newProduct.title}
            onChange={(e) =>
              setNewProduct({ ...newProduct, title: e.target.value })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />

          <TextField
            label='Item Code'
            value={newProduct.itemCode}
            onChange={(e) =>
              setNewProduct({ ...newProduct, itemCode: e.target.value })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />

          <Button
            variant='contained'
            component='label'
            sx={{
              mt: 2,
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            Upload Image
            <input type='file' hidden onChange={handleImageChange} />
          </Button>

          {selectedImage && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              <img
                src={
                  selectedImage instanceof File
                    ? URL.createObjectURL(selectedImage)
                    : `${apiHost}${selectedImage}`
                }
                alt='Selected'
                style={{
                  width: '100px',
                  height: '100px',
                  objectFit: 'fill',
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveProduct}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add Material'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductManagement;
