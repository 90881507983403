import axios from './axios';

const login = async (data) => {
  try {
    const response = await axios.post(`/auth`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updatePassword = async (data) => {
  try {
    const response = await axios.patch(`/auth/updatePassword`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const logout = async () => {
  try {
    const response = await axios.post(`/auth/logout`, {});

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const authService = {
  login,
  updatePassword,
  logout,
};

export default authService;
