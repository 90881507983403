import axios from './axios';

const createProduct = async (data) => {
  try {
    const response = await axios.post('/product', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const massUploadProducts = async (data) => {
  try {
    const response = await axios.post('/product/massUpload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllProducts = async () => {
  try {
    const response = await axios.get('/product');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateProduct = async (id, data) => {
  try {
    const response = await axios.patch(`/product/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteProduct = async (id) => {
  try {
    const response = await axios.delete(`/product/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteAllProducts = async (id) => {
  try {
    const response = await axios.delete(`/product`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const productService = {
  createProduct,
  massUploadProducts,
  getAllProducts,
  updateProduct,
  deleteProduct,
  deleteAllProducts,
};

export default productService;
