/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import toast from 'react-hot-toast';

import { apiHost } from '../constants';

axios.defaults.baseURL = apiHost;

const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('access-token');
  const refreshToken = localStorage.getItem('refresh-token');

  if (accessToken) {
    config.headers['access-token'] = accessToken;
  }
  if (refreshToken) {
    config.headers['refresh-token'] = refreshToken;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const accessToken = response.headers['access-token'];
    const refreshToken = response.headers['refresh-token'];

    if (accessToken) {
      localStorage.setItem('access-token', accessToken);
    }
    if (refreshToken) {
      localStorage.setItem('refresh-token', refreshToken);
    }

    return response;
  },
  (error) => {
    const currentPath = window.location.pathname;

    if (!error.response) {
      console.error('Network error:', error);
      toast.error('Something went wrong. Please try again!');
    } else if (error.response.status === 401) {
      if (currentPath !== '/login') {
        setTimeout(() => {
          localStorage.removeItem('access-token');
          localStorage.removeItem('refresh-token');
          localStorage.removeItem('user');
          window.location = '/login';
        }, 1000);
      }
    } else if (error.response.status === 403) {
      setTimeout(() => {
        window.location = '/';
      }, 1000);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
