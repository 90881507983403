import axios from './axios';

const createName = async (data) => {
  try {
    const response = await axios.post('/name', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllNames = async () => {
  try {
    const response = await axios.get('/name');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateName = async (id, data) => {
  try {
    const response = await axios.patch(`/name/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteName = async (id) => {
  try {
    const response = await axios.delete(`/name/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const nameService = {
  createName,
  getAllNames,
  updateName,
  deleteName,
};

export default nameService;
