import axios from './axios';

const createSource = async (data) => {
  try {
    const response = await axios.post('/source', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllSources = async () => {
  try {
    const response = await axios.get('/source');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateSource = async (id, data) => {
  try {
    const response = await axios.patch(`/source/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteSource = async (id) => {
  try {
    const response = await axios.delete(`/source/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const nameService = {
  createSource,
  getAllSources,
  updateSource,
  deleteSource,
};

export default nameService;
