import axios from './axios';

const createEmailerList = async (data) => {
  try {
    const response = await axios.post('/emailerList', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllEmailersList = async () => {
  try {
    const response = await axios.get('/emailerList');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateEmailerList = async (id, data) => {
  try {
    const response = await axios.patch(`/emailerList/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteEmailerList = async (id) => {
  try {
    const response = await axios.delete(`/emailerList/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const emailerListService = {
  createEmailerList,
  getAllEmailersList,
  updateEmailerList,
  deleteEmailerList,
};

export default emailerListService;
