import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import sourceService from '../services/source-service';

const SourceManagement = () => {
  const [sources, setSources] = useState([]);
  const [newSource, setNewSource] = useState({ value: '' });
  const [editSource, setEditSource] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchSources();
  }, []);

  const fetchSources = async () => {
    const data = await sourceService.getAllSources();
    if (data.error) setSources([]);
    else setSources(data);
  };

  const handleOpenDialog = (source = null) => {
    if (source) {
      setEditMode(true);
      setEditSource(source);
      setNewSource({ value: source.value });
    } else {
      setEditMode(false);
      setNewSource({ value: '' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditSource(null);
  };

  const handleSaveSource = async () => {
    if (editMode && editSource) {
      await sourceService.updateSource(editSource.id, newSource);
    } else {
      await sourceService.createSource(newSource);
    }
    fetchSources();
    handleCloseDialog();
  };

  const deleteSource = async (id) => {
    await sourceService.deleteSource(id);
    fetchSources();
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Procurement Sources
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 3,
          backgroundColor: '#1abc9c',
          '&:hover': {
            backgroundColor: '#16a085',
          },
          ml: 'auto',
          display: 'flex',
          alignItems: 'center',
          minWidth: 180,
          padding: '10px 20px',
        }}
      >
        Add New Procurement Source
      </Button>

      {sources.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Procurement Source
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sources.map((source) => (
                <TableRow hover key={source.id}>
                  <TableCell>{source.id}</TableCell>
                  <TableCell>{source.value}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(source)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteSource(source.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No procurement sources available
        </Typography>
      )}

      {/* Dialog for Add/Edit Source */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {editMode ? 'Edit Procurement Source' : 'Add New Procurement Source'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label='Procurement Source'
            value={newSource.value}
            onChange={(e) =>
              setNewSource({ ...newSource, value: e.target.value })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveSource}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add Procurement Source'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SourceManagement;
