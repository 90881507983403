import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import nameService from '../services/name-service';

const NameManagement = () => {
  const [names, setNames] = useState([]);
  const [newName, setNewName] = useState({ value: '' });
  const [editName, setEditName] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchNames();
  }, []);

  const fetchNames = async () => {
    const data = await nameService.getAllNames();
    if (data.error) setNames([]);
    else setNames(data);
  };

  const handleOpenDialog = (name = null) => {
    if (name) {
      setEditMode(true);
      setEditName(name);
      setNewName({ value: name.value });
    } else {
      setEditMode(false);
      setNewName({ value: '' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditName(null);
  };

  const handleSaveName = async () => {
    if (editMode && editName) {
      await nameService.updateName(editName.id, newName);
    } else {
      await nameService.createName(newName);
    }
    fetchNames();
    handleCloseDialog();
  };

  const deleteName = async (id) => {
    await nameService.deleteName(id);
    fetchNames();
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Names
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 3,
          backgroundColor: '#1abc9c',
          '&:hover': {
            backgroundColor: '#16a085',
          },
          ml: 'auto',
          display: 'flex',
          alignItems: 'center',
          minWidth: 180,
          padding: '10px 20px',
        }}
      >
        Add New Name
      </Button>

      {names.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {names.map((name) => (
                <TableRow hover key={name.id}>
                  <TableCell>{name.id}</TableCell>
                  <TableCell>{name.value}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(name)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteName(name.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No names available
        </Typography>
      )}

      {/* Dialog for Add/Edit Name */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>{editMode ? 'Edit Name' : 'Add New Name'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Name Value'
            value={newName.value}
            onChange={(e) => setNewName({ ...newName, value: e.target.value })}
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveName}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add Name'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NameManagement;
