import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Card,
  Grid2,
  CardContent,
  InputAdornment,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageIcon from '@mui/icons-material/Image';
import toast from 'react-hot-toast';
import nameService from '../services/name-service';
import categoryService from '../services/category-service';
import sourceService from '../services/source-service';
import availableShippingService from '../services/availableShipping-service';
import formDataService from '../services/formData-service';
import { apiHost } from '../constants';
import userService from '../services/user-service';
import projectService from '../services/project-service';

const JobForm = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState();
  const [projects, setProjects] = useState([]);
  const [names, setNames] = useState([]);
  const [formData, setFormData] = useState({
    projectId: '',
    jobNumber: '',
    nameId: '',
    quantities: {},
  });
  const [categories, setCategories] = useState([]);
  const [sources, setSources] = useState([]);
  const [availableShippings, setAvailableShippings] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [hasQuantities, setHasQuantities] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedShipping, setSelectedShipping] = useState('');

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const fetchMyUser = async () => {
      try {
        const response = await userService.getMyUser();

        if (response?.error) {
          if (!response?.error.includes('User not found')) {
            toast.error(response?.error);
          }
        } else {
          setUser(response);
        }
      } catch (error) {
        toast.error('Failed to fetch user');
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await projectService.getAllProjects();

        if (response?.error) {
          if (!response?.error.includes('No projects found')) {
            toast.error(response?.error);
          }
        } else {
          setProjects(response);
        }
      } catch (error) {
        toast.error('Failed to fetch projects');
      }
    };

    const fetchNames = async () => {
      try {
        const response = await nameService.getAllNames();

        if (response?.error) {
          if (!response?.error.includes('No names found')) {
            toast.error(response?.error);
          }
        } else {
          setNames(response || []);
        }
      } catch (error) {
        toast.error('Failed to fetch names');
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await categoryService.getAllCategories();

        if (response?.error) {
          if (!response?.error.includes('No categories found')) {
            toast.error(response?.error);
          }
        } else {
          setCategories(response || []);
        }
      } catch (error) {
        toast.error('Failed to fetch categories');
      }
    };

    const fetchSources = async () => {
      try {
        const response = await sourceService.getAllSources();
        setSources(response || []);
      } catch (error) {
        toast.error('Failed to fetch procurement sources');
      }
    };

    const fetchAvailableShippings = async () => {
      try {
        const response =
          await availableShippingService.getAllAvailableShippings();
        setAvailableShippings(response || []);
      } catch (error) {
        toast.error('Failed to fetch shippings');
      }
    };

    fetchMyUser();
    fetchProjects();
    fetchNames();
    fetchCategories();
    fetchSources();
    fetchAvailableShippings();
  }, []);

  const handleVerifyJob = async () => {
    setLoadingVerify(true);
    try {
      const response = await formDataService.verifyJobNumber({
        jobNumber: formData.jobNumber,
      });
      if (response && !response.error) {
        setIsVerified(true);
        toast.success('Project verified');
      } else {
        setIsVerified(false);
        toast.error('Invalid Project');
      }
    } catch (error) {
      toast.error('Failed to verify Project');
    } finally {
      setLoadingVerify(false);
    }
  };

  const handleQuantityChange = (productId, categoryId, value) => {
    const newQuantities = {
      ...formData.quantities,
      [productId]: {
        categoryId,
        productId,
        quantity: value,
      },
    };

    const hasQuantity = Object.values(newQuantities).some(
      (q) => q.quantity && parseInt(q.quantity, 10) > 0
    );
    setHasQuantities(hasQuantity);

    setFormData({
      ...formData,
      quantities: newQuantities,
    });
  };

  const resetForm = () => {
    setFormData({
      projectId: '',
      jobNumber: '',
      nameId: '',
      quantities: {},
    });
    setIsVerified(false);
    setHasQuantities(false);
    setSelectedSource('');
    setSelectedShipping('');
  };

  const handleFormSubmit = async () => {
    setLoadingSubmit(true);

    const finalPayload = {
      projectId: formData.projectId,
      jobNumber: formData.jobNumber,
      nameId: formData.nameId,
      data: Object.values(formData.quantities)
        .filter((q) => q.quantity && parseInt(q.quantity, 10) > 0)
        .map((q) => ({
          ...q,
          sourceId: selectedSource,
          shipToId: selectedShipping,
        })),
    };

    try {
      const response = await formDataService.createFormData(finalPayload);

      if (response?.error) {
        toast.error(response?.error);
      } else {
        toast.success(response?.message);
        resetForm();

        navigate('/thankYou', {
          state: { downloadUrl: response.formData.sheetPath },
        });
      }
    } catch (error) {
      toast.error('An error occurred during form submission');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleAccordionChange = (categoryId) => (event, isExpanded) => {
    setExpanded(isExpanded ? categoryId : false);
  };

  const filteredAndSortedCategories = categories
    .filter(
      (category) =>
        category.Products &&
        category.Products.length > 0 &&
        (!user?.restrictedCategories ||
          !user.restrictedCategories.includes(category.id.toString()))
    )
    .sort((a, b) => a.sortOrder - b.sortOrder);

  return (
    <Container maxWidth='md' sx={{ marginTop: '2rem' }}>
      <Typography
        variant='h4'
        align='center'
        gutterBottom
        sx={{ fontWeight: 'bold', color: '#2c3e50', marginBottom: '2rem' }}
      >
        Requisition Form
      </Typography>

      <Card
        sx={{
          padding: '2rem',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          borderRadius: '8px',
        }}
      >
        <CardContent>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <Autocomplete
              options={projects}
              getOptionLabel={(option) =>
                `${option.name} (${option.jobNumber})`
              }
              onChange={(event, value) => {
                setFormData({
                  ...formData,
                  projectId: value?.id || '',
                  jobNumber: value?.jobNumber || '',
                });
                setIsVerified(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Select Project'
                  margin='normal'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                    },
                  }}
                />
              )}
            />

            <TextField
              fullWidth
              margin='normal'
              value={formData.jobNumber}
              onChange={(e) =>
                setFormData({ ...formData, jobNumber: e.target.value })
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
              }}
              helperText='Select Project and click Verify'
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Button
                      variant='outlined'
                      onClick={handleVerifyJob}
                      disabled={!formData.jobNumber || loadingVerify}
                      startIcon={
                        loadingVerify ? (
                          <CircularProgress size={20} />
                        ) : (
                          <CheckCircleIcon
                            color={isVerified ? 'success' : 'inherit'}
                          />
                        )
                      }
                    >
                      {loadingVerify ? 'Verifying...' : 'Verify'}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />

            <FormControl
              fullWidth
              margin='normal'
              disabled={!isVerified || names.length === 0}
            >
              <InputLabel>Name</InputLabel>
              <Select
                label='Name'
                value={formData.nameId}
                onChange={(e) =>
                  setFormData({ ...formData, nameId: e.target.value })
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              >
                {names.length > 0 ? (
                  names.map((name) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.value}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Names Available</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              margin='normal'
              disabled={!isVerified || sources.length === 0}
            >
              <InputLabel>Procurement Source</InputLabel>
              <Select
                label='Procurement Source'
                value={selectedSource}
                onChange={(e) => setSelectedSource(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              >
                {sources.map((source) => (
                  <MenuItem key={source.id} value={source.id}>
                    {source.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              margin='normal'
              disabled={!isVerified || availableShippings.length === 0}
            >
              <InputLabel>Ship To</InputLabel>
              <Select
                label='Ship To'
                value={selectedShipping}
                onChange={(e) => setSelectedShipping(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                  },
                }}
              >
                {availableShippings.map((shipping) => (
                  <MenuItem key={shipping.id} value={shipping.id}>
                    {shipping.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {filteredAndSortedCategories.length > 0 ? (
            filteredAndSortedCategories
              .filter(
                (category) => category.Products && category.Products.length > 0
              )
              .map((category) => (
                <Accordion
                  key={category.id}
                  expanded={expanded === category.id}
                  onChange={handleAccordionChange(category.id)}
                  sx={{
                    marginBottom: '1rem',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    borderRadius: '15px',
                    '&:before': {
                      display: 'none',
                    },
                    '&.MuiAccordion-root:last-of-type': {
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                    },
                    '&:last-of-type': {
                      borderBottomLeftRadius: '15px',
                      borderBottomRightRadius: '15px',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${category.id}-content`}
                    id={`panel${category.id}-header`}
                    sx={{
                      backgroundColor: '#f5f5f5',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRadius: '15px',
                      '&:hover': {
                        backgroundColor: '#e9e9e9',
                      },
                      padding: '0px 15px',
                      '& .MuiAccordionSummary-content': {
                        margin: '25px 0',
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
                      {category.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '16px 24px' }}>
                    {category.Products.map((product) => (
                      <Grid2
                        container
                        spacing={2}
                        alignItems='center'
                        key={product.id}
                        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                      >
                        <Grid2 item xs={2}>
                          {product.imageUrl ? (
                            <img
                              src={`${apiHost}${product.imageUrl}`}
                              alt={product.title}
                              style={{
                                width: '60px',
                                height: '60px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                              }}
                            />
                          ) : (
                            <ImageIcon />
                          )}
                        </Grid2>

                        <Grid2 item xs={4}>
                          <Typography
                            sx={{ fontSize: '1rem', color: '#34495e' }}
                          >
                            {product.title}
                          </Typography>
                        </Grid2>

                        <Grid2 item xs={4}>
                          <TextField
                            label='Quantity'
                            type='number'
                            value={
                              formData.quantities[product.id]?.quantity || ''
                            }
                            onChange={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (value >= 0 || e.target.value === '') {
                                handleQuantityChange(
                                  product.id,
                                  category.id,
                                  value
                                );
                              }
                            }}
                            fullWidth
                            sx={{
                              ml: 4,
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                              },
                            }}
                            disabled={!isVerified}
                          />
                        </Grid2>
                      </Grid2>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))
          ) : (
            <Typography sx={{ color: 'gray', mt: 2 }}>
              No categories available
            </Typography>
          )}

          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleFormSubmit}
            disabled={
              !formData.jobNumber ||
              !formData.nameId ||
              !hasQuantities ||
              !selectedSource
            }
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '1.2rem',
              fontWeight: 'bold',
              borderRadius: '8px',
            }}
          >
            {loadingSubmit ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              'Submit'
            )}
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default JobForm;
