import { useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import authService from '../services/auth-service';

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('access-token');
    if (accessToken) {
      navigate('/');
    }
  }, [navigate]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await authService.login(values);

        if (response?.error) {
          toast.error(response?.error);
        } else {
          toast.success(response?.message);
          localStorage.setItem('user', JSON.stringify(response?.user));

          const event = new Event('authChanged');
          window.dispatchEvent(event);

          navigate('/');
        }
      } catch (error) {
        toast.error('An error occurred during login');
      }
    },
  });

  return (
    <Container maxWidth='sm'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Typography
          variant='h4'
          gutterBottom
          sx={{ fontWeight: 'bold', color: '#1a1a1a' }}
        >
          Welcome Back
        </Typography>

        <Typography
          variant='body1'
          gutterBottom
          sx={{ color: 'text.secondary', marginBottom: 2 }}
        >
          Log in to your account and continue.
        </Typography>

        <TextField
          label='Email Address'
          variant='outlined'
          fullWidth
          margin='normal'
          id='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        />

        <TextField
          label='Password'
          variant='outlined'
          fullWidth
          margin='normal'
          type='password'
          id='password'
          name='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
            },
            '& .MuiInputLabel-root': {
              fontSize: '1rem',
              fontWeight: 500,
            },
          }}
        />

        <Button
          variant='contained'
          fullWidth
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            borderRadius: '8px',
            padding: '12px 24px',
            fontSize: '1rem',
            fontWeight: 'bold',
            marginTop: 3,
            '&:hover': {
              backgroundColor: '#1565c0',
            },
          }}
          onClick={formik.handleSubmit}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
