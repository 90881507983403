import { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import userService from '../services/user-service';
import categoryService from '../services/category-service';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    restrictedCategories: [],
  });
  const [editUser, setEditUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchCategories();
  }, []);

  const fetchUsers = async () => {
    const data = await userService.getAllUsers();
    setUsers(data);
  };

  const fetchCategories = async () => {
    const data = await categoryService.getAllCategories();
    if (data.error) setCategories([]);
    else setCategories(data);
  };

  const handleOpenDialog = (user = null) => {
    if (user) {
      setEditMode(true);
      setEditUser(user);
      setNewUser({
        name: user.name,
        email: user.email,
        password: '',
        role: user.role,
        restrictedCategories: user.restrictedCategories || [],
      });
    } else {
      setEditMode(false);
      setNewUser({
        name: '',
        email: '',
        password: '',
        role: '',
        restrictedCategories: [],
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditUser(null);
  };

  const handleSaveUser = async () => {
    if (editMode && editUser) {
      await userService.updateUser(editUser.id, newUser);
    } else {
      if (newUser.role === 'Admin') await userService.registerAdmin(newUser);
      else await userService.registerUser(newUser);
    }
    fetchUsers();
    handleCloseDialog();
  };

  const deleteUser = async (id) => {
    await userService.deleteUser(id);
    fetchUsers();
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setNewUser({ ...newUser, restrictedCategories: value });
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Users
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 3,
          backgroundColor: '#1abc9c',
          '&:hover': {
            backgroundColor: '#16a085',
          },
          ml: 'auto',
          display: 'flex',
          alignItems: 'center',
          minWidth: 180,
          padding: '10px 20px',
        }}
      >
        Add New User
      </Button>

      {users.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Role
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Restricted Categories
                </TableCell>
                <TableCell
                  align='center'
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    {user.restrictedCategories
                      .map(
                        (categoryId) =>
                          categories.find(
                            (category) => category.id === parseInt(categoryId)
                          )?.title
                      )
                      .join(', ') || 'None'}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(user)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteUser(user.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No users available
        </Typography>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>{editMode ? 'Edit User' : 'Add New User'}</DialogTitle>
        <DialogContent>
          <TextField
            label='Name'
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
          <TextField
            label='Email'
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
          <TextField
            label='Password'
            type='password'
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
          <FormControl fullWidth margin='normal'>
            <InputLabel>Role</InputLabel>
            <Select
              label='Role'
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
              fullWidth
            >
              <MenuItem value='Admin'>Admin</MenuItem>
              <MenuItem value='Client'>Client</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <InputLabel>Restricted Categories</InputLabel>
            <Select
              label='Restricted Categories'
              multiple
              value={newUser.restrictedCategories}
              onChange={handleCategoryChange}
              renderValue={(selected) =>
                selected
                  .map(
                    (id) =>
                      categories.find((cat) => cat.id === parseInt(id))?.title
                  )
                  .join(', ')
              }
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  <Checkbox
                    checked={newUser.restrictedCategories.includes(category.id)}
                  />
                  <ListItemText primary={category.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveUser}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add User'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserManagement;
