import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
} from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import UserManagement from '../components/UserManagement';
import NameManagement from '../components/NameManagement';
import CategoryManagement from '../components/CategoryManagement';
import ProductManagement from '../components/ProductManagement';
import FormDataManagement from '../components/FormDataManagement';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import StorageIcon from '@mui/icons-material/Storage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SourceManagement from '../components/SourceManagement';
import AvailableShippingManagement from '../components/AvailableShippingManagement';
import ProjectManagement from '../components/ProjectManagement';
import EmailerListManagement from '../components/EmailerListManagement';

const drawerWidth = 240;

const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState(0);

  const sidebarItems = [
    { label: 'Users', icon: <PeopleIcon /> },
    { label: 'Projects', icon: <BusinessCenterIcon /> },
    { label: 'Names', icon: <DescriptionIcon /> },
    { label: 'Categories', icon: <CategoryIcon /> },
    { label: 'Materials', icon: <ShoppingCartIcon /> },
    { label: 'Procurement Sources', icon: <StorageIcon /> },
    { label: 'Warehouses', icon: <LocalShippingIcon /> },
    { label: 'Form Data', icon: <DescriptionIcon /> },
    { label: 'Email List', icon: <ContactMailIcon /> },
  ];

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');

    window.location.href = '/login';
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: '#1a1a1a',
        }}
      >
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='logo'
            component={Link}
            to='/'
          >
            <Avatar
              src={'/logo.png'}
              alt='Logo'
              sx={{ width: 40, height: 40 }}
            />
          </IconButton>
          <Typography variant='h6' noWrap sx={{ flexGrow: 1 }}>
            Benfield Electric
          </Typography>
          <Button
            color='inherit'
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#34495e',
            color: 'white',
          },
        }}
        variant='permanent'
        anchor='left'
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#2c3e50',
            color: 'white',
            padding: '20px 0',
            fontWeight: 'bold',
          }}
        >
          <AdminPanelSettingsIcon fontSize='large' sx={{ marginRight: 1 }} />
          <Typography variant='h6'>Admin Dashboard</Typography>
        </Toolbar>

        <List>
          {sidebarItems.map((item, index) => (
            <ListItem
              button
              key={item.label}
              onClick={() => setActiveTab(index)}
              selected={activeTab === index}
              sx={{
                cursor: 'pointer',
                backgroundColor: activeTab === index ? '#2980b9' : 'inherit',
                '&:hover': {
                  backgroundColor: '#1abc9c',
                  color: 'white',
                },
                '&.Mui-selected': {
                  backgroundColor: '#16a085',
                  color: 'white',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#1abc9c',
                  },
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    activeTab === index ? 'white' : 'rgba(255, 255, 255, 0.7)',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={{
                  color:
                    activeTab === index ? 'white' : 'rgba(255, 255, 255, 0.7)',
                }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          marginTop: '64px',
        }}
      >
        <Container>
          {activeTab === 0 && <UserManagement />}
          {activeTab === 1 && <ProjectManagement />}
          {activeTab === 2 && <NameManagement />}
          {activeTab === 3 && <CategoryManagement />}
          {activeTab === 4 && <ProductManagement />}
          {activeTab === 5 && <SourceManagement />}
          {activeTab === 6 && <AvailableShippingManagement />}
          {activeTab === 7 && <FormDataManagement />}
          {activeTab === 8 && <EmailerListManagement />}
        </Container>
      </Box>
    </Box>
  );
};

export default AdminPanel;
