import { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import availableShippingService from '../services/availableShipping-service';

const AvailableShippingManagement = () => {
  const [availableShippings, setAvailableShippings] = useState([]);
  const [newAvailableShipping, setNewAvailableShipping] = useState({
    value: '',
  });
  const [editAvailableShipping, setEditAvailableShipping] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchAvailableShippings();
  }, []);

  const fetchAvailableShippings = async () => {
    const data = await availableShippingService.getAllAvailableShippings();
    if (data.error) setAvailableShippings([]);
    else setAvailableShippings(data);
  };

  const handleOpenDialog = (availableShipping = null) => {
    if (availableShipping) {
      setEditMode(true);
      setEditAvailableShipping(availableShipping);
      setNewAvailableShipping({ value: availableShipping.value });
    } else {
      setEditMode(false);
      setNewAvailableShipping({ value: '' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditAvailableShipping(null);
  };

  const handleSaveAvailableShipping = async () => {
    if (editMode && editAvailableShipping) {
      await availableShippingService.updateAvailableShipping(
        editAvailableShipping.id,
        newAvailableShipping
      );
    } else {
      await availableShippingService.createAvailableShipping(
        newAvailableShipping
      );
    }
    fetchAvailableShippings();
    handleCloseDialog();
  };

  const deleteAvailableShipping = async (id) => {
    await availableShippingService.deleteAvailableShipping(id);
    fetchAvailableShippings();
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Warehouses
      </Typography>

      <Button
        startIcon={<AddIcon />}
        variant='contained'
        color='primary'
        onClick={() => handleOpenDialog()}
        sx={{
          mb: 3,
          backgroundColor: '#1abc9c',
          '&:hover': {
            backgroundColor: '#16a085',
          },
          ml: 'auto',
          display: 'flex',
          alignItems: 'center',
          minWidth: 180,
          padding: '10px 20px',
        }}
      >
        Add New Warehouse
      </Button>

      {availableShippings.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Warehouse
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableShippings.map((availableShipping) => (
                <TableRow hover key={availableShipping.id}>
                  <TableCell>{availableShipping.id}</TableCell>
                  <TableCell>{availableShipping.value}</TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => handleOpenDialog(availableShipping)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() =>
                        deleteAvailableShipping(availableShipping.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No warehouses available
        </Typography>
      )}

      {/* Dialog for Add/Edit Available Shipping */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          {editMode ? 'Edit Warehouse' : 'Add New Warehouse'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label='Warehouse'
            value={newAvailableShipping.value}
            onChange={(e) =>
              setNewAvailableShipping({
                ...newAvailableShipping,
                value: e.target.value,
              })
            }
            fullWidth
            margin='normal'
            variant='outlined'
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color='error'>
            Cancel
          </Button>
          <Button
            onClick={handleSaveAvailableShipping}
            color='primary'
            variant='contained'
            sx={{
              backgroundColor: '#1abc9c',
              '&:hover': {
                backgroundColor: '#16a085',
              },
            }}
          >
            {editMode ? 'Save Changes' : 'Add Warehouse'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AvailableShippingManagement;
