import axios from './axios';

const createProject = async (data) => {
  try {
    const response = await axios.post('/project', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllProjects = async () => {
  try {
    const response = await axios.get('/project');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateProject = async (id, data) => {
  try {
    const response = await axios.patch(`/project/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteProject = async (id) => {
  try {
    const response = await axios.delete(`/project/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const projectService = {
  createProject,
  getAllProjects,
  updateProject,
  deleteProject,
};

export default projectService;
