import axios from './axios';

const registerUser = async (data) => {
  try {
    const response = await axios.post('/user/register', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const registerAdmin = async (data) => {
  try {
    const response = await axios.post('/user/registerAdmin', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getMyUser = async () => {
  try {
    const response = await axios.get('/user/me');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const getAllUsers = async () => {
  try {
    const response = await axios.get('/user');

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateMyUser = async (data) => {
  try {
    const response = await axios.patch('/user/me', data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const updateUser = async (id, data) => {
  try {
    const response = await axios.patch(`/user/${id}`, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`/user/${id}`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const userService = {
  registerUser,
  registerAdmin,
  getMyUser,
  getAllUsers,
  updateMyUser,
  updateUser,
  deleteUser,
};

export default userService;
