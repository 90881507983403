import { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import formDataService from '../services/formData-service';
import { apiHost } from '../constants';

const FormDataManagement = () => {
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {
    const data = await formDataService.getAllFormDatas();
    if (data.error) setFormData([]);
    else setFormData(data);
  };

  const deleteFormData = async (id) => {
    await formDataService.deleteFormData(id);
    fetchFormData();
  };

  return (
    <div>
      <Typography variant='h4' gutterBottom>
        Manage Form Data
      </Typography>

      {formData.length > 0 ? (
        <TableContainer component={Paper} sx={{ mt: 4, boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  ID
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  User
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Project
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Data
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    backgroundColor: '#f0f0f0',
                    color: '#333',
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.map((data) => (
                <TableRow hover key={data.id}>
                  <TableCell>{data.id}</TableCell>
                  <TableCell>{data.User.name}</TableCell>
                  <TableCell>{`${data.Project.name} (${data.Project.jobNumber})`}</TableCell>
                  <TableCell>{data.Name.value}</TableCell>
                  <TableCell
                    style={{
                      width: '50%',
                    }}
                  >
                    {JSON.stringify(data.data)}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color='primary'
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = `${apiHost}${data.sheetPath}`;
                        link.setAttribute('download', `Requisition_Sheet.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                      disabled={!data.sheetPath}
                    >
                      <SimCardDownloadIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => deleteFormData(data.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='body1' sx={{ mt: 2 }}>
          No form data available
        </Typography>
      )}
    </div>
  );
};

export default FormDataManagement;
