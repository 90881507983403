import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './pages/Signup';
import Login from './pages/Login';
import JobForm from './pages/JobForm';
import AdminPanel from './pages/AdminPanel';
import Navbar from './components/Navbar';
import ThankYouPage from './pages/ThankYou';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/signup' element={<Signup />} />
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<JobForm />} />
        <Route path='/thankYou' element={<ThankYouPage />} />
        <Route path='/admin' element={<AdminPanel />} />
      </Routes>
    </Router>
  );
}

export default App;
